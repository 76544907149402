<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">企业会员积分管理</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="form_box" :model="submitData" label-position='right'  ref="sizeForm" label-width="120px" size="small">
                    <el-form-item label="企业会员：">
						<el-select  v-model="submitData.userId" style="width:300px;" clearable placeholder="请选择企业会员"  @change="chooseProvince($event,data)">
							<el-option v-for="item in data" :key="item.id" :label="item.enterpriseName" :value="item.id"> </el-option>
						</el-select>
					</el-form-item>
                    <el-form-item label="可用积分余额："  style="margin-top:30px;">
                        <span>{{integralBalance}}</span>
                    </el-form-item>
                    <el-form-item label="积分："  style="margin-top:30px;">
						<el-input  :maxlength="50" style="width:300px;" v-model="submitData.integral" placeholder="请输入操作积分" clearable></el-input>
					</el-form-item>
                    <el-form-item label="积分事件："  style="margin-top:30px;">
						<el-select  v-model="submitData.type" clearable placeholder="请选择状态"  style="width:300px;">
							<el-option v-for="item in eventOptions"  :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item>
                    <el-form-item size="large" style="margin-top:30px;">
                        <el-button  type="primary" @click="intergralSubmit(submitData)">执行</el-button>
                    </el-form-item>
                </el-form>
			</div>
		</div>
	</div>
</template>

<script>
import {
    enterprisememberintegralQueryList,enterprisememberintegralUpdate
} from "@/api/platform/index";
export default {
    name: "enterprisememberintegralQueryList",
    data() {
        return {
            data: [],
            submitData:{
                integral:'',
                type:'',
                userId:''
            },
            eventOptions: [{label: '添加',value: 6},{label: '减少',value: 7},{label: '冻结',value: 8},{label: '解冻',value: 9}],
            integralBalance:'0.00'
        };
    },
    mounted() {
        let that = this;
        that.list();
    },
    created() {
    },
    methods: {
        chooseProvince(e,data){
            if(e != null && e.length > 0){
                var index = data.findIndex((value)=> value.id == e);
                this.integralBalance = this.data[index].integralBalance;
            }else{
                this.integralBalance = '0.00'
            }
        },
        //获取列表
        list() {
            enterprisememberintegralQueryList({}).then(res => {
                if(res.code == 200){
                    this.data = res.data;
                }
                
            })
        },
        
        // 执行操作
        intergralSubmit(data){
            let that = this;
            if(data.userId != null && data.userId.length > 0){
                if(data.integral != null && data.integral.length > 0){
                    if(data.type != null && data.type > 0){
                        that.$confirm('您确定要' + (data.type == 6 ? '添加' : data.type == 7 ? '减少' : data.type == 8 ? '冻结' : '解冻') + '积分?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            enterprisememberintegralUpdate({
                                userId: data.userId,
                                integral: data.integral,
                                type: data.type,
                            }).then(res=>{
                                if (res.code == 200) {
                                    that.$message.success('操作成功');
                                    that.submitData = {
                                        integral: '',
                                        type: '',
                                        userId:''
                                    }
                                    that.page = 1
                                    that.list();
                                } else {
                                    that.$message.error(res.msg);
                                }
                            }).catch(err => {
                                that.$message.error(err.msg);
                            })
                        })
                    }else{
                        that.$message.warning('请先选择操作类型');
                    }
                }else{
                    that.$message.warning('操作积分不能为空');
                }
            }else{
                that.$message.warning('请先选择企业');
            }
        },
    }
}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:150px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
</style>
